const binding = { modules: {}, dataActions: {} };

    (binding.modules['default-page'] = {
        c: () => require('@msdyn365-commerce-modules/core-components/dist/lib/modules/default-page/default-page'),
        $type: 'pageModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'default-page',
        p: 'core-components',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/core-components/dist/lib/modules/default-page'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };