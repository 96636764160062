import React from 'react';
import { ProductSearchResult, ProductType, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

interface IAddToCartListComponentProps {
    product: ProductSearchResult;
    addToCart(id: string | number, caseQuantity: number, eachQuantity: number): void;
    addToCartButtonText: string;
    displayEaPrice: boolean;
    factor: number | undefined;
    displayCsPrice: boolean;
    csAvailable: number;
    eaAvailable: number;
    handleCartLineUpdate: (cl: { product: SimpleProduct; quantity: number }[]) => void;
    eaQty: number;
    csQty: number;
}

const EA_UOM_STR = 'ea';
const CS_UOM_STR = 'cs';

export const AddToCartListComponent: React.FC<IAddToCartListComponentProps> = ({
    product,
    addToCart,
    addToCartButtonText,
    displayEaPrice,
    factor,
    displayCsPrice,
    csAvailable,
    eaAvailable,
    handleCartLineUpdate,
    csQty,
    eaQty
}) => {
    if (!factor) {
        factor = 99;
    }
    const createProduct = (uom: string): SimpleProduct => {
        return {
            BasePrice: product.BasePrice || product.Price,
            AdjustedPrice: product.Price,
            Name: product.Name,
            RecordId: product.RecordId,
            ProductTypeValue: ProductType.Variant,
            Price: product.Price,
            DefaultUnitOfMeasure: uom,
            ItemId: product.ItemId
        };
    };

    const handleCaseQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const qty = Math.floor(Math.min(e.target.valueAsNumber, csAvailable));
        if (qty === csAvailable) {
            setQuantity([
                { qty, uom: CS_UOM_STR },
                { qty: Math.min(eaAvailable, eaQty), uom: EA_UOM_STR }
            ]);
        } else {
            setQuantity([{ qty, uom: CS_UOM_STR }]);
        }
    };

    const incrementCaseQuantity = () => {
        if (csAvailable === 0) {
            return;
        }
        if (csQty + 1 === csAvailable) {
            setQuantity([
                { qty: csQty + 1, uom: CS_UOM_STR },
                { qty: Math.min(eaQty, eaAvailable), uom: EA_UOM_STR }
            ]);
        } else {
            setQuantity([{ qty: csQty + 1, uom: CS_UOM_STR }]);
        }
        // }
    };
    const decrementCaseQuantity = () => {
        if (csQty !== 0) {
            setQuantity([{ qty: csQty - 1, uom: CS_UOM_STR }]);
        }
    };

    const handleEachQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuantity([
            { qty: csQty === csAvailable ? Math.min(e.target.valueAsNumber, eaAvailable) : e.target.valueAsNumber, uom: EA_UOM_STR }
        ]);
    };

    const setQuantity = (qtys: { qty: number; uom: string }[]) => {
        handleCartLineUpdate(
            qtys.map(q => {
                return { product: createProduct(q.uom), quantity: q.qty };
            })
        );
    };

    const handleEachQuantityOnBlur = () => {
        if (displayCsPrice) {
            let remaining: number = eaQty;
            if (factor && eaQty >= factor) {
                let cases: number = 0;
                while (remaining >= factor) {
                    remaining -= factor;
                    cases++;
                }
                const newTotalCases = csQty + cases;
                if (newTotalCases >= csAvailable) {
                    setQuantity([
                        { qty: csAvailable, uom: CS_UOM_STR },
                        { qty: Math.min(eaAvailable, remaining), uom: EA_UOM_STR }
                    ]);
                } else {
                    setQuantity([
                        { qty: csQty + cases, uom: CS_UOM_STR },
                        { qty: remaining, uom: EA_UOM_STR }
                    ]);
                }
            }
        }
    };
    const incrementEachQuantity = () => {
        if (displayCsPrice) {
            const _factor = factor || 1;
            if (csQty === csAvailable && eaQty === eaAvailable % _factor) {
                return;
            }
            if (eaQty < _factor - 1) {
                setQuantity([{ qty: eaQty + 1, uom: EA_UOM_STR }]);
            } else if (csQty !== csAvailable) {
                setQuantity([
                    { qty: csQty + 1, uom: CS_UOM_STR },
                    { qty: 0, uom: EA_UOM_STR }
                ]);
            }
        } else {
            setQuantity([{ qty: eaQty + 1, uom: EA_UOM_STR }]);
        }
    };
    const decrementEachQuantity = () => {
        if (eaQty !== 0) {
            setQuantity([{ qty: eaQty - 1, uom: EA_UOM_STR }]);
        }
    };

    return (
        <>
            <td className={`list-view__quantity-container`}>
                {displayEaPrice && displayCsPrice ? (
                    <>
                        <div className='list-view__quantity-container__top-div'>
                            <button
                                type={'button'}
                                className={'list-view__quantity-container__decrement-button'}
                                onClick={decrementCaseQuantity}
                                disabled={!csAvailable}
                            >
                                -
                            </button>
                            <input
                                className='list-view__change-quantity'
                                type='number'
                                min={'0'}
                                value={csQty}
                                aria-label={'Change Quantity'}
                                onChange={handleCaseQuantityChange}
                                disabled={!csAvailable}
                            />
                            <button
                                type={'button'}
                                className={'list-view__quantity-container__increment-button'}
                                onClick={incrementCaseQuantity}
                                disabled={!csAvailable || csQty === csAvailable}
                            >
                                +
                            </button>
                            <p className='list-view__quantity-container__label'>Cs.</p>
                        </div>
                        <div className='list-view__quantity-container__bottom-div'>
                            <button
                                type={'button'}
                                className={'list-view__quantity-container__decrement-button'}
                                onClick={decrementEachQuantity}
                                disabled={eaQty === 0}
                            >
                                -
                            </button>
                            <input
                                className='list-view__change-quantity'
                                type='number'
                                min={'0'}
                                max={'100'}
                                value={eaQty}
                                aria-label={'Change Quantity'}
                                onChange={handleEachQuantityChange}
                                onBlur={handleEachQuantityOnBlur}
                                disabled={csQty === csAvailable && !eaAvailable}
                            />
                            <button
                                type={'button'}
                                className={'list-view__quantity-container__increment-button'}
                                onClick={incrementEachQuantity}
                                disabled={csQty === csAvailable && eaQty === eaAvailable}
                            >
                                +
                            </button>
                            <p className='list-view__quantity-container__label'>Ea.</p>
                        </div>
                    </>
                ) : displayCsPrice ? (
                    <div className='list-view__quantity-container__full-div'>
                        <button
                            type={'button'}
                            className={'list-view__quantity-container__decrement-button'}
                            onClick={decrementCaseQuantity}
                            disabled={!csAvailable}
                        >
                            -
                        </button>
                        <input
                            className='list-view__change-quantity'
                            type='number'
                            min={'0'}
                            max={'100'}
                            value={csQty}
                            aria-label={'Change Quantity'}
                            onChange={handleCaseQuantityChange}
                            disabled={!csAvailable}
                        />
                        <button
                            type={'button'}
                            className={'list-view__quantity-container__increment-button'}
                            onClick={incrementCaseQuantity}
                            disabled={!csAvailable || csAvailable === csQty}
                        >
                            +
                        </button>
                        <p className='list-view__quantity-container__label'>Cs.</p>
                    </div>
                ) : (
                    <div className='list-view__quantity-container__full-div'>
                        <button
                            type={'button'}
                            className={'list-view__quantity-container__decrement-button'}
                            onClick={decrementEachQuantity}
                            disabled={!eaAvailable || eaQty === 0}
                        >
                            -
                        </button>
                        <input
                            className='list-view__change-quantity'
                            type='number'
                            min={'0'}
                            value={eaQty}
                            aria-label={'Change Quantity'}
                            // onChange={handleEachQuantityChange}
                            disabled={csQty === csAvailable && !eaAvailable}
                        />
                        <button
                            type={'button'}
                            className={'list-view__quantity-container__increment-button'}
                            onClick={incrementEachQuantity}
                            disabled={csQty === csAvailable && eaAvailable === eaQty}
                        >
                            +
                        </button>
                        <p className='list-view__quantity-container__label'>Ea.</p>
                    </div>
                )}
                {/*{quantityError && <p className={'list-view-__quantity-error-text'}>Please enter a quantity</p>}*/}
            </td>
            <td>
                {/*<button type={'button'} className={'list-view__add-to-cart-btn msc-button'} onClick={handleAddToCart} disabled={!available}>*/}
                {/*    {addToCartButtonText}*/}
                {/*</button>*/}
            </td>
        </>
    );
};
