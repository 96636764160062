import React from 'react';

const DropdownIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<path d="M7 10l5 5 5-5z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
);

export default DropdownIcon;
