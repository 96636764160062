import { IActionContext } from '@msdyn365-commerce/core-internal';

/**
 * Gets Catalog ID from the action context object
 * @param context
 * @constructor
 */
export const GetCatalogIdFromContext = (context: IActionContext): number => {
    if (!context.requestContext.user) {
        return 0;
    }
    let catalogId =
        (context.requestContext.query && context.requestContext.query['catalogid'] && +context.requestContext.query['catalogid']) || 0;
    if (catalogId === 0) {
        catalogId = (context.requestContext.user.catalogId && +context.requestContext.user.catalogId) || 0;
    }

    return catalogId;
};
