import React from 'react';

const FirstPage: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		aria-hidden="true"
		role="presentation"
	>
		<path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
		<path fill="none" d="M24 24H0V0h24v24z" />
	</svg>
);

export default FirstPage;
