import React from 'react';

interface IAddToCartListComponentProps {
    itemId: number;
    addToCart(id: number, caseQuantity: number, eachQuantity: number): void;
    addToCartButtonText: string;
    displayEaPrice: boolean;
    factor: number | undefined;
    displayCsPrice: boolean;
    available: boolean;
}

export const AddToCartListComponent: React.FC<IAddToCartListComponentProps> = ({
    itemId,
    addToCart,
    addToCartButtonText,
    displayEaPrice,
    factor,
    displayCsPrice,
    available
}) => {
    const [caseQuantity, setCaseQuantity] = React.useState<number>(0);
    const [eachQuantity, setEachQuantity] = React.useState<number>(0);
    const [quantityError, setQuantityError] = React.useState<boolean>(false);
    if (!factor) factor = 99;

    React.useEffect(() => {
        setQuantityError(false);
    }, [caseQuantity, eachQuantity])
    const handleCaseQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCaseQuantity(e.target.valueAsNumber);
    };
    const handleAddToCart = () => {
        if (!eachQuantity && !caseQuantity) {
            setQuantityError(true);
        }
        addToCart(itemId, caseQuantity, eachQuantity);
        setCaseQuantity(0);
        setEachQuantity(0);
    };
    const incrementCaseQuantity = () => {
        if (caseQuantity !== 100) setCaseQuantity(caseQuantity + 1);
    };
    const decrementCaseQuantity = () => {
        if (caseQuantity !== 0) setCaseQuantity(caseQuantity - 1);
    };

    const handleEachQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEachQuantity(e.target.valueAsNumber);
    };

    const handleEachQuantityOnBlur = () => {
        if (displayCsPrice) {
            let remaining: number = eachQuantity;
            if (eachQuantity > 100) {
                remaining = 100;
            }
            if (factor && eachQuantity >= factor) {
                let cases: number = 0;
                while (remaining >= factor) {
                    remaining -= factor;
                    cases++;
                }
                setCaseQuantity(caseQuantity + cases);
                setEachQuantity(remaining);
            }
        }
    };
    const incrementEachQuantity = () => {
        if (displayCsPrice) {
            if (eachQuantity < (factor ?? 100) - 1) {
                setEachQuantity(eachQuantity + 1);
            } else {
                setCaseQuantity(caseQuantity + 1);
                setEachQuantity(0);
            }
        } else {
            setEachQuantity(eachQuantity + 1);
            console.log(eachQuantity);
        }
    };
    const decrementEachQuantity = () => {
        if (eachQuantity !== 0) setEachQuantity(eachQuantity - 1);
    };

    return (
        <>
            <td className={`list-view__quantity-container ${quantityError ? 'list-view__quantity-container--error': ''}`}>
                {displayEaPrice && displayCsPrice ? (
                    <>
                        <div className='list-view__quantity-container__top-div'>
                            <button
                                type={'button'}
                                className={'list-view__quantity-container__decrement-button'}
                                onClick={decrementCaseQuantity}
                                disabled={!available}
                            >
                                -
                            </button>
                            <input
                                className='list-view__change-quantity'
                                type='number'
                                min={'0'}
                                max={'100'}
                                value={caseQuantity}
                                aria-label={'Change Quantity'}
                                onChange={handleCaseQuantityChange}
                                disabled={!available}
                            />
                            <button
                                type={'button'}
                                className={'list-view__quantity-container__increment-button'}
                                onClick={incrementCaseQuantity}
                                disabled={!available}
                            >
                                +
                            </button>
                            <p className='list-view__quantity-container__label'>Cs.</p>
                        </div>
                        <div className='list-view__quantity-container__bottom-div'>
                            <button
                                type={'button'}
                                className={'list-view__quantity-container__decrement-button'}
                                onClick={decrementEachQuantity}
                                disabled={!available}
                            >
                                -
                            </button>
                            <input
                                className='list-view__change-quantity'
                                type='number'
                                min={'0'}
                                max={'100'}
                                value={eachQuantity}
                                aria-label={'Change Quantity'}
                                onChange={handleEachQuantityChange}
                                onBlur={handleEachQuantityOnBlur}
                                disabled={!available}
                            />
                            <button
                                type={'button'}
                                className={'list-view__quantity-container__increment-button'}
                                onClick={incrementEachQuantity}
                                disabled={!available}
                            >
                                +
                            </button>
                            <p className='list-view__quantity-container__label'>Ea.</p>
                        </div>
                    </>
                ) : displayCsPrice ? (
                    <div className='list-view__quantity-container__full-div'>
                        <button
                            type={'button'}
                            className={'list-view__quantity-container__decrement-button'}
                            onClick={decrementCaseQuantity}
                            disabled={!available}
                        >
                            -
                        </button>
                        <input
                            className='list-view__change-quantity'
                            type='number'
                            min={'0'}
                            max={'100'}
                            value={caseQuantity}
                            aria-label={'Change Quantity'}
                            onChange={handleCaseQuantityChange}
                            disabled={!available}
                        />
                        <button
                            type={'button'}
                            className={'list-view__quantity-container__increment-button'}
                            onClick={incrementCaseQuantity}
                            disabled={!available}
                        >
                            +
                        </button>
                        <p className='list-view__quantity-container__label'>Cs.</p>
                    </div>
                ) : (
                    <div className='list-view__quantity-container__full-div'>
                        <button
                            type={'button'}
                            className={'list-view__quantity-container__decrement-button'}
                            onClick={decrementEachQuantity}
                            disabled={!available}
                        >
                            -
                        </button>
                        <input
                            className='list-view__change-quantity'
                            type='number'
                            min={'0'}
                            max={'100'}
                            value={eachQuantity}
                            aria-label={'Change Quantity'}
                            onChange={handleEachQuantityChange}
                            disabled={!available}
                        />
                        <button
                            type={'button'}
                            className={'list-view__quantity-container__increment-button'}
                            onClick={incrementEachQuantity}
                            disabled={!available}
                        >
                            +
                        </button>
                        <p className='list-view__quantity-container__label'>Ea.</p>
                    </div>
                )}
                {quantityError && <p className={'list-view-__quantity-error-text'}>Please enter a quantity</p>}
            </td>
            <td>
                <button type={'button'} className={'list-view__add-to-cart-btn msc-button'} onClick={handleAddToCart} disabled={!available}>
                    {addToCartButtonText}
                </button>
            </td>
        </>
    );
};
